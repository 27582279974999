import './index.css';
import { Routes, Route } from 'react-router-dom';
import ProfileCard from './components/ProfileCard';
import About from './pages/About'
import Links from './pages/Links'

function App() {

  return (
    <div className='flex flex-col items-center bg-orange-50 min-h-screen'>
    <ProfileCard />
    <div>
    <Routes>
      <Route exact path='/' element={<About/>}/>
      <Route path ='/about' element = {<About/>}/>
      <Route path = '/links' element = {<Links />}/>
    </Routes>
    </div>
    </div>
  );
}

export default App;
