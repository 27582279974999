import React, { useState } from "react";

const Buttons = ({ buttonTexts }) => {
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const handleClick = (text) => {
        if (text.startsWith('tel:')) {
            window.location.href = text; // Initiate call
        } else if (text.startsWith('mailto:')) {
            window.location.href = text; // Open email client
        } else if (text === 'copy to clipboard') {
            copyToClipboard('starkpadua.com');
        } else {
            // Handle other actions as needed
            console.log(`Clicked on: ${text}`);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setFeedbackMessage(`Copied to clipboard!`);
                setTimeout(() => {
                    setFeedbackMessage('');
                }, 5000); // Display message for 5 seconds
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
                setFeedbackMessage('Failed to copy to clipboard.');
                setTimeout(() => {
                    setFeedbackMessage('');
                }, 500); // Display message for 5 seconds on failure as well
            });
    };

    return (
        <>
            {buttonTexts.map(({ icon: Icon, text }, index) => (
                <button key={index} className='bg-green-900 text-white rounded-full p-3 text-lg focus:outline-none m-2 shadow-dark-md' onClick={() => handleClick(text)}>
                    <Icon />
                </button>
            ))}
            {feedbackMessage && (
                <p className="text-green-900 mt-2">{feedbackMessage}</p>
            )}
        </>
    );
};

export default Buttons;
