import React from 'react';

const IconWrapper = ({ Icon }) => {
  return (
    <div className="bg-white text-neutral-500 rounded-full border border-green-900 border-r-2 border-b-2 p-2 text-lg m-2">
      <Icon />
    </div>
  );
};

export default IconWrapper;