import React from 'react';
import profileImg from '../images/profileimg.png';
import { PiPhoneFill, PiEnvelopeFill, PiLink } from 'react-icons/pi';
import Buttons from './Buttons';

const ProfileCard = () => {
    const buttonTexts = [
        { icon: PiPhoneFill, text: 'tel:8456493822' },
        { icon: PiEnvelopeFill, text: 'mailto:stark7991@gmail.com' },
        { icon: PiLink, text: 'copy to clipboard' }
    ];

    return (
        <div className="flex flex-col items-center justify-center mt-10 min-h-25%">
            <div className="flex flex-col items-center">
                <img src={profileImg} alt="profile picture" className="h-24 w-24 rounded-full border border-r-4 border-b-4 border-green-900" />
                <h2 className="text-2xl text-green-900 font-serif mt-4">Ashley Stark</h2>
                <p className="text-green-900 mt-2 text-xs text-center">front end developer, tech enthusiast, & traveler</p>
                <div className="flex flex-row items-center mt-4">
                    <Buttons buttonTexts={buttonTexts} />
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
