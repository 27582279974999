import React from "react";
import { Link } from 'react-router-dom';
import IconWrapper from '../components/IconWrapper';
import { PiLinkedinLogo, PiPerson, PiPhone, PiEnvelope } from 'react-icons/pi';

const Links = () => {
  return (
    <div className="w-80 mt-6 px-8 bg-white rounded-xl border-t-4 border-b-4 border-green-900 shadow-lg">
      <div className="flex justify-center m-4">
        <Link
          to="/about"
          className="text-xs py-2 px-4 rounded-l-full bg-gray-200 text-green-900">
          ABOUT
        </Link>
        <Link
          to="/links"
          className="text-xs py-2 px-4 rounded-r-full bg-green-900 text-white">
          LINKS
        </Link>
      </div>
      {/* Content for the "Links" page */}
      <div className="flex items-center justify-center mb-2">
        <div className="w-full border-t border-black"></div>
        <div className="px-4 text-center text-sm text-green-900 tracking-widest">SOCIAL</div>
        <div className="w-full border-t border-black"></div>
      </div>
      <div className="flex items-center mb-4">
        <IconWrapper Icon={PiLinkedinLogo} />
        <div className="ml-2 text-left">
          <div className="text-xs text-gray-500">LinkedIn</div>
          <div className="text-sm text-green-900">
            <a href="https://www.linkedin.com/in/starkpadua/">linkedin.com/starkpadua</a>
          </div>
        </div>
      </div>
      <div className="flex items-center mb-4">
        <IconWrapper Icon={PiPerson} />
        <div className="ml-2 text-left">
          <div className="text-xs text-gray-500">MY WEBSITE</div>
          <div className="text-sm text-green-900">
            <a href="https://www.starkpadua.com">www.starkpadua.com</a>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mb-2">
        <div className="w-full border-t border-black"></div>
        <div className="px-4 text-center text-sm text-green-900 tracking-widest">CONTACT</div>
        <div className="w-full border-t border-black"></div>
      </div>
      <div className="flex items-center mb-4">
        <IconWrapper Icon={PiPhone} />
        <div className="ml-2 text-left">
          <div className="text-xs text-gray-500">PHONE</div>
          <div className="text-sm text-green-900">
            <a href="tel:+18456493822">(845) 649-3822</a>
          </div>
        </div>
      </div>
      <div className="flex items-center mb-4">
        <IconWrapper Icon={PiEnvelope} />
        <div className="ml-2 text-left">
          <div className="text-xs text-gray-500">EMAIL</div>
          <div className="text-sm text-green-900">
            <a href="mailto:stark7991@gmail.com">stark7991@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Links;
