import React from "react";
import { Link } from 'react-router-dom';
import IconWrapper from '../components/IconWrapper';
import { PiSuitcaseFill, PiListBullets, PiLink, PiGraduationCap, PiClock } from 'react-icons/pi';

const About = () => {
  return (
    <div className="w-80 mt-6 px-8 bg-white rounded-xl border-t-4 border-b-4 border-green-900 shadow-lg">
      <div className="flex justify-center m-4">
        <Link
          to="/about"
          className="text-xs py-2 px-4 rounded-l-full bg-green-900 text-white">
          ABOUT
        </Link>
        <Link
          to="/links"
          className="text-xs py-2 px-4 rounded-r-full bg-gray-200 text-green-900">
          LINKS
        </Link>
      </div>
      {/* Content for the "ABOUT" page */}
      <div className="flex items-center justify-center mb-2">
        <div className="w-full border-t border-black"></div>
        <div className="px-4 text-center text-sm text-green-900 tracking-widest">ABOUT</div>
        <div className="w-full border-t border-black"></div>
      </div>
      <div className="flex items-center mb-4">
        <IconWrapper Icon={PiSuitcaseFill} />
        <div className="ml-2 text-left">
          <div className="text-xs text-gray-500">TITLE</div>
          <div className="text-sm text-green-900">Front End Developer</div>
        </div>
      </div>
      <div className="flex items-center mb-4">
        <IconWrapper Icon={PiListBullets} />
        <div className="ml-2 text-left">
          <div className="text-xs text-gray-500">COMPANY</div>
          <div className="text-sm text-green-900">Fulgums Bar</div>
        </div>
      </div>
      <div className="flex items-center mb-4">
        <IconWrapper Icon={PiLink} />
        <div className="ml-2 text-left">
          <div className="text-xs text-gray-500">WEBSITE</div>
          <div className="text-sm text-green-900">
            <a href="https://www.fulgumsbar.com">www.fulgumsbar.com</a>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mb-2">
        <div className="w-full border-t border-black"></div>
        <div className="px-4 text-center text-sm text-green-900 tracking-widest">EDUCATION</div>
        <div className="w-full border-t border-black"></div>
      </div>
      <div className="flex items-center mb-4">
        <IconWrapper Icon={PiGraduationCap} />
        <div className="ml-2 text-left">
          <div className="text-xs text-gray-500">SCHOOL</div>
          <div className="text-sm text-green-900">Full Sail University</div>
        </div>
      </div>
      <div className="flex items-center mb-4">
        <IconWrapper Icon={PiClock} />
        <div className="ml-2 text-left">
          <div className="text-xs text-gray-500">DURATION</div>
          <div className="text-sm text-green-900">2017-2024</div>
        </div>
      </div>
    </div>
  );
}

export default About;
